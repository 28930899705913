/* =======================================

このような悩み

======================================= */

/* セクション */
.sectionTrouble {
	padding-top: 0;
	background: #fff;
}

@include md-min {
	.sectionTrouble {
		padding-top: 80px;
		background: url(../images/trouble/trouble_bg.jpg) no-repeat center top / 1200px auto #fff;
	}
}

@include lg-min {
	.sectionTrouble {
		background-size: 100% auto;
	}
}

@media screen and (min-width: 1600px) {

}

/* 見出し */
.trouble_haed {
	margin: 0 0 20px;
	font-weight: normal;

	img {
		display: block;
		width: 100%;
		height: auto;
	}
}

@include md-min {
	.trouble_haed {
		width: 50%;
		margin: 0 0 40px;
	}
}

/* チェックリスト */
.checkList-trouble {
	margin-bottom: 50px;
}

@include md-min {
	.checkList-trouble {
		width: 50%;
		margin-bottom: 80px;
	}
}


/* 悩みのリスト */
.trouble_examples {
	padding: 20px 0;
	//background: #fff;
}

.troubleItem {
	padding: 0 20px;

	&:first-child {
		margin-bottom: 30px;
	}
}

.troubleItem_img {
	margin-bottom: 20px;
}

.troubleItem_heading {
	@include font-size(18);

	margin-bottom: 20px;
	font-weight: 500;
	color: #46464f;

	span {
		display: block;
		font-size: 0.66em;
	}
}

@include sm-min {
	.trouble_examples {
		padding: 40px 0;
	}

	.troubleItem {
		padding: 0 40px;

		&:first-child {
			margin-bottom: 40px;
		}
	}
}

@include md-min {
	.trouble_examples {
		padding: 40px 20px;
	}

	.troubleItem {
		float: left;
		width: 50%;
		padding: 0 20px;
	}
}

@include lg-min {
	.trouble_examples {
		padding: 40px 0;
		margin: 0 (-40px);
		//background-color: rgba(#fff, 0.8);
	}

	.troubleItem {
		float: left;
		width: 50%;
		padding: 0 40px;

		&:first-child {
			margin: 0;
			//border-right: solid 1px #ddd;
		}
	}
}
// .troubleList {
// 	padding: 40px 0;
// 	background-color: #9cc9eb;
// }

// .troubleList_box {
// 	padding: 20px 10px;
// 	background-color: #fff;
// 	border-radius: 10px;
// }

// .checkList-trouble {
// 	display: flex;
// 	justify-content: center;
// }

// @include sm-min {
// 	.troubleList_box {
// 		padding: 30px;
// 	}
// }

// /* 具体例部分 */
// .trouble_examples {
// 	padding: 0 0 70px;
// }

// .troubleItem {
// 	margin: 0 0 $spaceMedium;
// }

// .troubleItem_inner {
// 	height: 100%;
// 	background: #fff;
// 	border: solid 5px #f9f9f9;
// }

// .troubleItem_txt {
// 	padding: 15px;
// }

// @include sm-min {
// 	.troubleItem_txt {
// 		padding: 30px;
// 	}
// }

// @include md-min {
// 	.trouble_examples {
// 		padding: 160px 0 130px;
// 	}

// 	.troubleItem_txt {
// 		padding: 15px;
// 	}
// }

// @include lg-min {
// 	.troubleItem_txt {
// 		padding: 30px;
// 	}
// }

// .troubleItem_heading {
// 	@include font-size(18);

// 	margin: 0 0 $spaceXSmall;
// 	font-weight: 500;
// 	color: #46464f;

// 	span {
// 		display: block;
// 		font-size: 0.66em;
// 	}
// }
