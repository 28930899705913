/* =======================================

メタケイ酸の美肌の秘密……

======================================= */
.silica-big-title{
	text-align: center;
	color: #fff;
	font-size: 14vw;
	letter-spacing: 5px;
}



.sectionSilica {
	//background: url(../images/silica/silica_bg.jpg) repeat center;
}

.silica_mainimage {
	//box-shadow: 7px 7px 20px -10px rgba(#000, 0.2);
}

.silica_txt {
	margin-bottom: 200px;

	p {
		max-width: 760px;
		margin: 0 auto;
	}
}

/* 効果4つ */
.kouka_item {
	margin-bottom: $spaceLarge;

	&:last-child {
		margin-bottom: 0;
	}
}

.kouka_heading {
	@include font-size(22);

	padding: 10px;
	margin-bottom: $spaceSmall;
	color: #fff;
	text-align: center;
	background-color: $mainBlue;
	border-radius: 50px;
	font-weight: 400;
}

.kouka_body {
	padding: 0 10px;
}

.kouka_chart {
	max-width: 450px;
	margin: 0 auto;
}

@include md-min {

.silica-img{
	width: 310px;
	margin: 0 auto;
}
	.kouka_item:nth-last-child(2) {
		margin-bottom: 0;
	}
}

@include lg-min {
	.silica-big-title{
		text-align: center;
		color: #fff;
		font-size: 130px;
		letter-spacing: 5px;
	}
}