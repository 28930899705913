/* =======================================

セクション

======================================= */
.section {
	position: relative;
	padding: 100px 0;
	background: #fff;
}

.section-bg {
	position: relative;
	padding: 100px 0;
	background: #e4e4e4;
}

.section-bg02 {
	position: relative;
	padding: 100px 0;
	background: #6696ca;
}

.sectionGradientLin {
	@include gradientLin;

	&.LinBack {
		@include gradientLinBack;
	}

	&.bottomArr,
	&.topArr {
		overflow: hidden;
	}


	&.bottomArr::before {
		position: absolute;
		right: 50%;
		bottom: 0;
		display: block;
		width: 0;
		height: 0;
		content: "";
		border-color: transparent transparent transparent #fff;
		border-style: solid;
		border-width: 70px 0 0 50vw;
	}

	&.bottomArr::after {
		position: absolute;
		bottom: 0;
		left: 50%;
		display: block;
		width: 0;
		height: 0;
		content: "";
		border-color: transparent transparent #fff transparent;
		border-style: solid;
		border-width: 0 0 70px 50vw;
	}

	&.topArr::before {
		position: absolute;
		top: 0;
		left: 50%;
		display: block;
		width: 0;
		height: 0;
		content: "";
		border-color: #fff transparent transparent transparent;
		border-style: solid;
		border-width: 70px 50vw 0 50vw;
		transform: translateX(-50%);
	}
}

.sectionGradientRad {
	@include gradientRad;
}


.sectuon-title-img{
	width: 90%;
	max-width: 310px;
	margin: 0 auto;
}


.section-separate-sm{
	margin-bottom: 30px;
}
.section-separate-md{
	margin-bottom: 40px;
}

.section-title{
	font-weight: 400;
	font-size: 20px;
	text-align: center;
	letter-spacing: 2px;
	color: $fontColor;
}
.section-title-text{
	text-align: center;
	font-weight: 400;
	line-height: 1.8;
}
.section-title-text-left{
	font-weight: 400;
	line-height: 1.8;
}

.section-dot{
	text-align: center;
}
@include md-min {
	.section-separate-sm{
		margin-bottom: 40px;
	}
	.section-separate-md{
		margin-bottom: 60px;
	}
	.section-title{
		font-size: 28px;
	}
	.section-title-text{
		letter-spacing: 2px;
		font-size: 19px;
	}
	.section-title-text-left{
		letter-spacing: 2px;
		font-size: 19px;
	}
}