/* =======================================

設定

======================================= */

/* フォント */
body {
	font-family: 'Noto Sans JP', sans-serif;
	font-weight: 300;
	line-height: 1.7;
}

.serif {
	font-family: '游明朝','Yu Mincho',YuMincho,'Hiragino Mincho Pro',serif;
}

@include sm-max {
	html {
		font-size: 87.5%;
	}
}

/* 横幅 */
.container {
	@include container;
}


a{
	text-decoration: none;
}
