/* =======================================

バスタイム

======================================= */

.bathtime-resort-wrap{
  padding: 40px 0;
	position: relative;

	&::before{
		content: '=';
		position: absolute;
		font-size: 56px;
		color: $mainBrown;
		top: 0;
		left: 0;
		margin-top: 60px;
    z-index: 1;
	}

	.bathtime-resort-title{
		width: 200px;
	}

	.bathtime-resort-img{
		width: 90%;
		margin: 0 auto;
	}
	.bathtime-resort-sub{
		position: absolute;
		font-weight: bold;
		font-size: 16px;
		bottom: 0;
		right: 0;
		margin-right: -120px;
		margin-bottom: 130px;
		transform: rotate(-270deg);
		transform-origin:left top;
		&::before{
			content: '|　';
		}
	}
}

.bathtime-item-wrap{
	text-align: center;
	position: relative;
	font-weight: normal;


	
  span.bathtime-item-box{
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
    display: inline-block;
		background: #e4e4e4;
		z-index: 3;
		padding-left: 20px;
		padding-right: 20px;
	}

	span.bathtime-item{
		display: inline-block;
		background-color: #bc537d;
		font-size: 12px;
		width: 50px;
		height: 50px;
		border-radius: 25px;
		color: #fff;
		padding-top: 14px;
		margin-right: 20px;
	}

	span.bathtime-item-title{
		display: inline-block;
		letter-spacing: 3px;
		font-size: 24px;
	}
	&:after{
    content: '';
		position: absolute;
		top: 50%;
		left: 0;
		width: 100%;
		height: 1px;
		background-color: #666;
		z-index: 1;

	}
}

@include md-min {

	.bathtime-resort-wrap{
		padding: 40px 0;
		position: relative;
	
		&::before{
			font-size: 56px;
			margin-top: 60px;
			margin-left: 30px;;
		}
	
		.bathtime-resort-title{
			width: 280px;
		}

		.bathtime-resort-img{
			width: 80%;
		}

		.bathtime-resort-sub{
			font-size: 20px;
			margin-right: -100px;
			margin-bottom: 150px;

		}
	}

	.bathtime-pro-box{
	  width: 600px;
		margin: 0 auto;
	}

}
