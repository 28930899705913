// ======================================
// 色
// ======================================
$mainBlue: #6696ca;
$mainRed: 	#bc537d;
$mainBrown: #9e7349;
$fontColor: #000;
$borderColor: #ddd;

// ======================================
// 各種数値
// ======================================

// ======================================
// グリッド
// ======================================
// グリッドのスペース
$gridSpace: 40px;

// ブレイクポイント
$breakpoint-sm: 480px;
$breakpoint-md: 768px;
$breakpoint-lg: 1024px;
$breakpoint-xl: 1440px;

// ======================================
// コンテナー
// ======================================

// コンテナーの幅
$container: 1000px;
$containerSmall:	700px;
// ======================================
// 要素同士のマージン
// ======================================
$spaceSection: 60px;
$spaceXLarge: 50px;
$spaceLarge: 40px;
$spaceMedium: 30px;
$spaceSmall: 20px;
$spaceXSmall: 10px;
