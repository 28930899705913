/* =======================================

製品紹介

======================================= */
.section_product {
	position: relative;
	padding: 100px 0;

	// &::after {
	// 	position: absolute;
	// 	bottom: 0;
	// 	left: 0;
	// 	display: block;
	// 	width: 100%;
	// 	height: 80px;
	// 	content: "";
	// 	background: rgb(255, 255, 255);
	// 	background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 30%, rgba(255, 255, 255, 0) 100%);
	// }
}

/* タイトル */
.heading_product {
	margin: $spaceSmall 0;
	line-height: 1.5;
	text-align: center;
}

.heading_product_sub {
	display: inline-block;
	margin-bottom: 0;
	text-align: center;
}

.heading_product_logo {
	display: block;
	max-width: 640px;
	margin: $spaceSmall auto $spaceLarge;
}

/* 写真と横並び部分 - 写真 */
.product_wrapper {
	position: relative;
	background-image: url(../images/product/img_product01_sp.png);
	background-repeat: no-repeat;
	background-position: 0 0;
	background-size: contain;
}

.product_wrapper {
	padding-top: 60%;
}

@include lg-min {
	.product_wrapper {
		padding-top: 0;
		background-image: url(../images/product/img_product01_pc.png);
		background-position: center center;
	}

	.product_disc {
		padding-right: 50%;
	}
}

@media screen and (-ms-high-contrast: none) {
	/* ここに書く */
	.product_wrapper {
		background-image: url(../images/product/img_product01_pc.png), linear-gradient(0, rgba(97, 201, 247, 1) 0%, rgba(32, 180, 245, 1) 35%, rgba(0, 133, 227, 1) 100%);
	}
}

/* 写真と横並び部分 - テキスト */
.product_disc {
	margin-top: $spaceSmall;
}

.product_copy {
	@include font-size(27);

	margin-bottom: $spaceSmall;
	color: #46464f;

	span {
		display: inline-block;
	}
}

.product_copy_sub {
	@include font-size(18);
}

.product_info {
	padding-top: $spaceMedium;
	margin: $spaceMedium 0;
	border-top: solid 1px rgba(#fff, 0.35);
}

.product_info_seibun {
	margin-bottom: $spaceSmall;
}

.product_info_name {
	@include font-size(27);

	line-height: 1.5;
	color: #fff;

	span {
		display: inline-block;
	}
}

.product_info_sub {
	color: #fff;
}

.product_link {
	.btn {
		@include font-size(24);

		width: 100%;
		max-width: 320px;
		padding: 20px 0;
	}
}

@media (min-width: 480px) and (max-width: 1023px) {
	.product_disc {
		text-align: center;
	}
}

@include sm-min {
	.product_copy_sub span {
		display: block;
	}
}
