/* =======================================

見出し

======================================= */
.heading_lv2 {
	@include font-size(30);

	margin: 0 0 $spaceMedium;
	font-weight: 300;
	line-height: 1.5;
	color: #46464f;
	text-align: center;

	span {
		display: inline-block;
	}

	span.marker {
		color: $mainRed;
	}
}

@include sm-max {
	.heading_lv2 {
		@include font-size(27);

		text-align: left;

		br {
			display: none;
		}
	}
}

.heading_lv3 {
	@include font-size(24);

	margin: 0 0 $spaceMedium;
	color: $mainRed;

	@include sm-min {
		text-align: center;
	}
}
