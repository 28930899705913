/* ======================================

汎用クラス

====================================== */

/* クリア */
.clearfix {
	@include clearfix;
}

/* 文字揃え */
.u-text-align-left {
	text-align: left !important;
}

.u-text-align-center {
	text-align: center !important;
}

.u-text-align-right {
	text-align: right !important;
}

/* 文字サイズ */

.u-font-size-small {
	font-size: 0.8em !important;
}

.u-font-size-large {
	font-size: 1.3em !important;
}

/* 太字 */
.u-font-weight-bold {
	font-weight: bold !important;
}

.u-font-weight-normal {
	font-weight: normal !important;
}

/* 太字色 */
.u-color-red {
	color: red !important;
}

.u-color-wh {
	color: #fff !important;
}

/* マージン */
.u-margin-top-xlarge {
	margin-top: $spaceXLarge !important;
}

.u-margin-top-large {
	margin-top: $spaceLarge !important;
}

.u-margin-top-medium {
	margin-top: $spaceMedium !important;
}

.u-margin-top-small {
	margin-top: $spaceSmall !important;
}

.u-margin-top-xsmall {
	margin-top: $spaceXSmall !important;
}

.u-margin-top-0 {
	margin-top: 0 !important;
}

.u-margin-bottom-xlarge {
	margin-bottom: $spaceXLarge !important;
}

.u-margin-bottom-large {
	margin-bottom: $spaceLarge !important;
}

.u-margin-bottom-medium {
	margin-bottom: $spaceMedium !important;
}

.u-margin-bottom-small {
	margin-bottom: $spaceSmall !important;
}

.u-margin-bottom-xsmall {
	margin-bottom: $spaceXSmall !important;
}

.u-margin-bottom-0 {
	margin-bottom: 0 !important;
}

.u-margin-right-0 {
	margin-right: 0 !important;
}

.u-margin-left-0 {
	margin-left: 0 !important;
}

/* パディング */
.u-padding-top-xlarge {
	padding-top: $spaceXLarge !important;
}

.u-padding-top-large {
	padding-top: $spaceLarge !important;
}

.u-padding-top-medium {
	padding-top: $spaceMedium !important;
}

.u-padding-top-small {
	padding-top: $spaceSmall !important;
}

.u-padding-top-xsmall {
	padding-top: $spaceXSmall !important;
}

.u-padding-top-0 {
	padding-top: 0 !important;
}

.u-padding-bottom-xlarge {
	padding-bottom: $spaceXLarge !important;
}

.u-padding-bottom-large {
	padding-bottom: $spaceLarge !important;
}

.u-padding-bottom-medium {
	padding-bottom: $spaceMedium !important;
}

.u-padding-bottom-small {
	padding-bottom: $spaceSmall !important;
}

.u-padding-bottom-xsmall {
	padding-bottom: $spaceXSmall !important;
}

.u-padding-bottom-0 {
	padding-bottom: 0 !important;
}

.u-padding-right-0 {
	padding-right: 0 !important;
}

.u-padding-left-0 {
	padding-left: 0 !important;
}

/* 非表示 */
.u-hide-sm-min {
	@include sm-min {
		display: none !important;
	}
}

.u-hide-md-min {
	@include md-min {
		display: none !important;
	}
}

.u-hide-lg-min {
	@include lg-min {
		display: none !important;
	}
}

.u-hide-xl-min {
	@include xl-min {
		display: none !important;
	}
}

.u-hide-sm-max {
	@include sm-max {
		display: none !important;
	}
}

.u-hide-md-max {
	@include md-max {
		display: none !important;
	}
}

.u-hide-lg-max {
	@include lg-max {
		display: none !important;
	}
}

.u-hide-xl-max {
	@include xl-max {
		display: none !important;
	}
}

/* 非表示 - デバイス */
@media screen and (min-width: $breakpoint-md) and (max-width: #{$breakpoint-lg - 1}) {
	.u-hide-tablet {
		display: none !important;
	}
}


/* ポジション */
.u-position-relative {
	position: relative;
}

/* 画像横幅いっぱい */
.u-img-w100 {
	display: block;
	width: 100%;
	height: auto;
}


//改行
.brp { display: block; }
.brs { display: none; }

@include md-min {
	.brp { display: none; }
	.brs { display: block; }
}

//ふわっ
.animation{
	opacity : 0;
	visibility: hidden;
	transition: 1s;
	transform: translateY(30px);
}
.active{
	opacity: 1;
	visibility: visible;
	transform: translateY(0);
}
