/* =======================================

フッター

======================================= */
.footer_grid {
	align-items: center;
}

.footer_photo {
	margin-bottom: $spaceMedium;

	img {
		display: block;
		width: 100%;
		//max-width: 174px;
		height: auto;
		margin: 0 auto;
	}
}

.footer_txt {
	line-height: 1.3;
}

.footer_name {
	@include font-size(24);
	font-weight: bold;
	letter-spacing: 3px;

	margin-bottom: $spaceXSmall;

	span {
		display: inline-block;
		@include font-size(16);
	}
}

.footer_price {
	margin-bottom: $spaceSmall;

	span {
		display: inline-block;
	}
}

.footer_component {
	@include font-size(13);
}

.footer_link {
	margin-top: $spaceMedium;

	.btn {
		width: 100%;
	}

	.footer_txt & {
		display: none;
	}
}

.footer_info {
	@include font-size(13);

	padding-top: $spaceSmall;
	margin-top: $spaceMedium;
	line-height: 1.3;
	border-top: solid 1px $borderColor;
}

@include sm-min {
	.footer_photo {
		margin-bottom: 0;
	}
}

@include md-min {
	.footer_txt .footer_link {
		display: block;
	}

	.footer_link .btn {
		width: auto;
	}
}

/* フッター */
.footer {
	padding: $spaceSmall;
	border-top: solid 1px $borderColor;
}

.footer_logo {
	text-align: center;

	img {
		width: 100%;
		max-width: 300px;
	}
}

.copyright {
	padding-top: 5px;
	font-size: 12px;
	text-align: center;

	a {
		color: $fontColor;
		text-decoration: underline;

		&:visited {
			color: $fontColor;
		}

		&:hover {
			text-decoration: none;
		}
	}
}

@include md-min {
	.footer_container {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}
