/* =======================================

flexbox

======================================= */
.flex_grid {
	display: flex;
	flex-wrap: wrap;
	margin: 0 (-$gridSpace/2);
}

.flex_col {
	width: 100%;
	padding: 0 $gridSpace/2;
}

@for $var from 1 to 13 {
	.col_#{$var} {
		width: (100% / 12) * $var;
	}
}

@include sm-min {
	@for $var from 1 to 13 {
		.col_sm_#{$var} {
			width: (100% / 12) * $var;
		}
	}
}

@include md-min {
	@for $var from 1 to 13 {
		.col_md_#{$var} {
			width: (100% / 12) * $var;
		}
	}
}

@include lg-min {
	@for $var from 1 to 13 {
		.col_lg_#{$var} {
			width: (100% / 12) * $var;
		}
	}
}
