/* =======================================

メタケイ酸

======================================= */
#metakei {
	padding: 100px 0;
}

.sectionMetakei {
	background: url(../images/metakei/metakei_bg.jpg) no-repeat center / cover;
}

/* 含んでいます */
.including {
	@include font-size(22);

	padding: 5px 10px;
	margin-bottom: $spaceMedium;
	font-weight: 500;
	color: $mainBrown;
	text-align: center;
	background-color: rgba(#fff, 0.4);
	border: solid 1px $mainBrown;

	span {
		display: inline-block;
		text-align: left;
	}
}

@include sm-min {
	.including {
		@include font-size(24);
	}
}

/* 働き */
.metakeiWork_head {
	@include font-size(20);

	margin-bottom: $spaceSmall;
	font-weight: 300;
	text-align: center;
}

.metakeiWork_list {
	justify-content: center;
}

.metakeiWork_item {
	margin-bottom: $spaceSmall;

	&:last-child {
		margin-bottom: 0;
	}
}

@include lg-min {
	.metakeiWork_item {
		margin-bottom: 0;
	}
}

.metakeiWork_box {
	height: 100%;
	padding: 20px;
	background-color: #fff;
}

.metakeiWork_title {
	@include font-size(22);

	padding: 5px;
	margin-bottom: 5px;
	font-weight: 500;
	color: #fff;
	text-align: center;
	background-color: $mainBlue;
	border-radius: 5px;
}

.metakeiWork_ill {
	display: block;
	width: 100%;
	max-width: 400px;
	margin: 0 auto $spaceXSmall;
}
