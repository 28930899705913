@charset "UTF-8";
/* ======================================

汎用クラス

====================================== */
/* クリア */
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

/* 文字揃え */
.u-text-align-left {
  text-align: left !important;
}

.u-text-align-center {
  text-align: center !important;
}

.u-text-align-right {
  text-align: right !important;
}

/* 文字サイズ */
.u-font-size-small {
  font-size: 0.8em !important;
}

.u-font-size-large {
  font-size: 1.3em !important;
}

/* 太字 */
.u-font-weight-bold {
  font-weight: bold !important;
}

.u-font-weight-normal {
  font-weight: normal !important;
}

/* 太字色 */
.u-color-red {
  color: red !important;
}

.u-color-wh {
  color: #fff !important;
}

/* マージン */
.u-margin-top-xlarge {
  margin-top: 50px !important;
}

.u-margin-top-large {
  margin-top: 40px !important;
}

.u-margin-top-medium {
  margin-top: 30px !important;
}

.u-margin-top-small {
  margin-top: 20px !important;
}

.u-margin-top-xsmall {
  margin-top: 10px !important;
}

.u-margin-top-0 {
  margin-top: 0 !important;
}

.u-margin-bottom-xlarge {
  margin-bottom: 50px !important;
}

.u-margin-bottom-large {
  margin-bottom: 40px !important;
}

.u-margin-bottom-medium {
  margin-bottom: 30px !important;
}

.u-margin-bottom-small {
  margin-bottom: 20px !important;
}

.u-margin-bottom-xsmall {
  margin-bottom: 10px !important;
}

.u-margin-bottom-0 {
  margin-bottom: 0 !important;
}

.u-margin-right-0 {
  margin-right: 0 !important;
}

.u-margin-left-0 {
  margin-left: 0 !important;
}

/* パディング */
.u-padding-top-xlarge {
  padding-top: 50px !important;
}

.u-padding-top-large {
  padding-top: 40px !important;
}

.u-padding-top-medium {
  padding-top: 30px !important;
}

.u-padding-top-small {
  padding-top: 20px !important;
}

.u-padding-top-xsmall {
  padding-top: 10px !important;
}

.u-padding-top-0 {
  padding-top: 0 !important;
}

.u-padding-bottom-xlarge {
  padding-bottom: 50px !important;
}

.u-padding-bottom-large {
  padding-bottom: 40px !important;
}

.u-padding-bottom-medium {
  padding-bottom: 30px !important;
}

.u-padding-bottom-small {
  padding-bottom: 20px !important;
}

.u-padding-bottom-xsmall {
  padding-bottom: 10px !important;
}

.u-padding-bottom-0 {
  padding-bottom: 0 !important;
}

.u-padding-right-0 {
  padding-right: 0 !important;
}

.u-padding-left-0 {
  padding-left: 0 !important;
}

/* 非表示 */
@media screen and (min-width: 480px) {
  .u-hide-sm-min {
    display: none !important;
  }
}

@media screen and (min-width: 768px) {
  .u-hide-md-min {
    display: none !important;
  }
}

@media screen and (min-width: 1024px) {
  .u-hide-lg-min {
    display: none !important;
  }
}

@media screen and (min-width: 1440px) {
  .u-hide-xl-min {
    display: none !important;
  }
}

@media screen and (max-width: 479px) {
  .u-hide-sm-max {
    display: none !important;
  }
}

@media screen and (max-width: 767px) {
  .u-hide-md-max {
    display: none !important;
  }
}

@media screen and (max-width: 1023px) {
  .u-hide-lg-max {
    display: none !important;
  }
}

@media screen and (max-width: 1439px) {
  .u-hide-xl-max {
    display: none !important;
  }
}

/* 非表示 - デバイス */
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .u-hide-tablet {
    display: none !important;
  }
}

/* ポジション */
.u-position-relative {
  position: relative;
}

/* 画像横幅いっぱい */
.u-img-w100 {
  display: block;
  width: 100%;
  height: auto;
}

.brp {
  display: block;
}

.brs {
  display: none;
}

@media screen and (min-width: 768px) {
  .brp {
    display: none;
  }
  .brs {
    display: block;
  }
}

.animation {
  opacity: 0;
  visibility: hidden;
  transition: 1s;
  transform: translateY(30px);
}

.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

/* =======================================

設定

======================================= */
/* フォント */
body {
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 300;
  line-height: 1.7;
}

.serif {
  font-family: '游明朝','Yu Mincho',YuMincho,'Hiragino Mincho Pro',serif;
}

@media screen and (max-width: 479px) {
  html {
    font-size: 87.5%;
  }
}

/* 横幅 */
.container {
  max-width: 740px;
  padding: 0 20px;
  margin: 0 auto;
}

@media screen and (min-width: 1024px) {
  .container {
    max-width: 1040px;
  }
}

a {
  text-decoration: none;
}

/* =======================================

flexbox

======================================= */
.flex_grid {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -20px;
}

.flex_col {
  width: 100%;
  padding: 0 20px;
}

.col_1 {
  width: 8.33333%;
}

.col_2 {
  width: 16.66667%;
}

.col_3 {
  width: 25%;
}

.col_4 {
  width: 33.33333%;
}

.col_5 {
  width: 41.66667%;
}

.col_6 {
  width: 50%;
}

.col_7 {
  width: 58.33333%;
}

.col_8 {
  width: 66.66667%;
}

.col_9 {
  width: 75%;
}

.col_10 {
  width: 83.33333%;
}

.col_11 {
  width: 91.66667%;
}

.col_12 {
  width: 100%;
}

@media screen and (min-width: 480px) {
  .col_sm_1 {
    width: 8.33333%;
  }
  .col_sm_2 {
    width: 16.66667%;
  }
  .col_sm_3 {
    width: 25%;
  }
  .col_sm_4 {
    width: 33.33333%;
  }
  .col_sm_5 {
    width: 41.66667%;
  }
  .col_sm_6 {
    width: 50%;
  }
  .col_sm_7 {
    width: 58.33333%;
  }
  .col_sm_8 {
    width: 66.66667%;
  }
  .col_sm_9 {
    width: 75%;
  }
  .col_sm_10 {
    width: 83.33333%;
  }
  .col_sm_11 {
    width: 91.66667%;
  }
  .col_sm_12 {
    width: 100%;
  }
}

@media screen and (min-width: 768px) {
  .col_md_1 {
    width: 8.33333%;
  }
  .col_md_2 {
    width: 16.66667%;
  }
  .col_md_3 {
    width: 25%;
  }
  .col_md_4 {
    width: 33.33333%;
  }
  .col_md_5 {
    width: 41.66667%;
  }
  .col_md_6 {
    width: 50%;
  }
  .col_md_7 {
    width: 58.33333%;
  }
  .col_md_8 {
    width: 66.66667%;
  }
  .col_md_9 {
    width: 75%;
  }
  .col_md_10 {
    width: 83.33333%;
  }
  .col_md_11 {
    width: 91.66667%;
  }
  .col_md_12 {
    width: 100%;
  }
}

@media screen and (min-width: 1024px) {
  .col_lg_1 {
    width: 8.33333%;
  }
  .col_lg_2 {
    width: 16.66667%;
  }
  .col_lg_3 {
    width: 25%;
  }
  .col_lg_4 {
    width: 33.33333%;
  }
  .col_lg_5 {
    width: 41.66667%;
  }
  .col_lg_6 {
    width: 50%;
  }
  .col_lg_7 {
    width: 58.33333%;
  }
  .col_lg_8 {
    width: 66.66667%;
  }
  .col_lg_9 {
    width: 75%;
  }
  .col_lg_10 {
    width: 83.33333%;
  }
  .col_lg_11 {
    width: 91.66667%;
  }
  .col_lg_12 {
    width: 100%;
  }
}

/* =======================================

セクション

======================================= */
.section {
  position: relative;
  padding: 100px 0;
  background: #fff;
}

.section-bg {
  position: relative;
  padding: 100px 0;
  background: #e4e4e4;
}

.section-bg02 {
  position: relative;
  padding: 100px 0;
  background: #6696ca;
}

.sectionGradientLin {
  background: #0085e3;
  background: linear-gradient(0, #61c9f7 0%, #20b4f5 35%, #0085e3 100%);
}

.sectionGradientLin.LinBack {
  background: #0085e3;
  background: linear-gradient(0, #0085e3 0%, #20b4f5 65%, #61c9f7 100%);
}

.sectionGradientLin.bottomArr, .sectionGradientLin.topArr {
  overflow: hidden;
}

.sectionGradientLin.bottomArr::before {
  position: absolute;
  right: 50%;
  bottom: 0;
  display: block;
  width: 0;
  height: 0;
  content: "";
  border-color: transparent transparent transparent #fff;
  border-style: solid;
  border-width: 70px 0 0 50vw;
}

.sectionGradientLin.bottomArr::after {
  position: absolute;
  bottom: 0;
  left: 50%;
  display: block;
  width: 0;
  height: 0;
  content: "";
  border-color: transparent transparent #fff transparent;
  border-style: solid;
  border-width: 0 0 70px 50vw;
}

.sectionGradientLin.topArr::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 0;
  height: 0;
  content: "";
  border-color: #fff transparent transparent transparent;
  border-style: solid;
  border-width: 70px 50vw 0 50vw;
  transform: translateX(-50%);
}

.sectionGradientRad {
  background: #eaf8ff;
  background: radial-gradient(circle, #eaf8ff 0%, #d2f1ff 100%);
}

.sectuon-title-img {
  width: 90%;
  max-width: 310px;
  margin: 0 auto;
}

.section-separate-sm {
  margin-bottom: 30px;
}

.section-separate-md {
  margin-bottom: 40px;
}

.section-title {
  font-weight: 400;
  font-size: 20px;
  text-align: center;
  letter-spacing: 2px;
  color: #000;
}

.section-title-text {
  text-align: center;
  font-weight: 400;
  line-height: 1.8;
}

.section-title-text-left {
  font-weight: 400;
  line-height: 1.8;
}

.section-dot {
  text-align: center;
}

@media screen and (min-width: 768px) {
  .section-separate-sm {
    margin-bottom: 40px;
  }
  .section-separate-md {
    margin-bottom: 60px;
  }
  .section-title {
    font-size: 28px;
  }
  .section-title-text {
    letter-spacing: 2px;
    font-size: 19px;
  }
  .section-title-text-left {
    letter-spacing: 2px;
    font-size: 19px;
  }
}

/* =======================================

見出し

======================================= */
.heading_lv2 {
  font-size: 1.875rem;
  margin: 0 0 30px;
  font-weight: 300;
  line-height: 1.5;
  color: #46464f;
  text-align: center;
}

.heading_lv2 span {
  display: inline-block;
}

.heading_lv2 span.marker {
  color: #bc537d;
}

@media screen and (max-width: 479px) {
  .heading_lv2 {
    font-size: 1.6875rem;
    text-align: left;
  }
  .heading_lv2 br {
    display: none;
  }
}

.heading_lv3 {
  font-size: 1.5rem;
  margin: 0 0 30px;
  color: #bc537d;
}

@media screen and (min-width: 480px) {
  .heading_lv3 {
    text-align: center;
  }
}

/* =======================================

リスト

======================================= */
.list {
  padding: 0 0 0 1.5em;
  margin: 0 0 30px;
}

.list li {
  margin: 0 0 10px;
}

.list li:last-child {
  margin: 0;
}

.checkList {
  padding: 0;
  margin: 0 0 30px;
  list-style: none;
}

.checkList li {
  font-size: 1.25rem;
  padding-left: 1.5em;
  margin: 0 0 10px;
  background: url(../images/icon_check_2c.svg) no-repeat 0 0.4em/auto 1em;
}

.checkList li:last-child {
  margin: 0;
}

.checkList li span {
  display: inline-block;
}

/* =======================================

要素いろいろ

======================================= */
/* ボタン */
.btn {
  display: inline-block;
  padding: 20px 3em;
  color: #fff;
  text-align: center;
  background-color: #bc537d;
  background-position: right 1em center;
  background-size: auto 1em;
  border-radius: 50px;
  transition: opacity 0.25s;
}

.btn:hover {
  opacity: 0.7;
}

/* 強調 */
em {
  font-style: normal;
  font-weight: bold;
}

strong {
  font-style: normal;
  font-weight: bold;
  color: #bc537d;
}

.marker {
  background: linear-gradient(transparent 70%, #ff0 70%);
}

/* =======================================

ヘッダー

======================================= */
/* イメージ部分 */
.mainImage {
  display: flex;
  flex-direction: column;
  min-height: 300px;
  max-height: 400px;
}

.mainImage-img {
  flex-grow: 1;
  background: url(../images/header/mainimage.jpg) no-repeat top/cover;
}

.mainImage-img_title {
  padding: 10px 40px;
  color: #9e7349;
  text-align: center;
}

.mainImage-img_logo img {
  width: 100%;
  max-width: 410px;
  height: auto;
  vertical-align: bottom;
}

.mainImage-img_name {
  font-size: 1.25rem;
}

@media screen and (min-width: 480px) {
  .mainImage {
    display: flex;
    flex-direction: column;
    min-height: 500px;
    max-height: 612px;
  }
  .mainImage-img {
    background: url(../images/header/mainimage.jpg) no-repeat center/cover;
    height: 100%;
  }
}

@media screen and (min-width: 768px) {
  .mainImage {
    display: flex;
    flex-direction: column;
    min-height: 600px;
    max-height: 812px;
  }
  .mainImage-img_title {
    padding: 100px 20px 30px 20px;
    color: #9e7349;
  }
}

@media screen and (min-width: 1024px) {
  .mainImage-img {
    padding: 70px 0 0;
  }
}

/* コピー部分 */
.mainImage-txt {
  padding: 20px 20px 40px;
  background-color: #eff7ff;
}

.mainImage-txt_inner {
  display: flex;
  width: 100%;
  max-width: 850px;
  margin: 0 auto;
}

.mainImage-product {
  position: relative;
  width: 30%;
}

.mainImage-product img {
  position: absolute;
  right: 0;
  bottom: 0;
  display: block;
  width: 100%;
}

.mainImage-copy {
  padding-left: 5%;
}

.mainImage-copy_main {
  font-size: 6.2vw;
  font-weight: normal;
  line-height: 1.5;
  color: #9e7349;
}

.mainImage-copy_sub {
  margin: 20px 0 0;
}

@media screen and (min-width: 768px) {
  .mainImage-copy_main {
    font-size: 5.9vw;
  }
}

@media screen and (min-width: 1024px) {
  .mainImage-product {
    width: 20%;
  }
  .mainImage-copy_sub {
    margin: 10px 0 0;
  }
  .mainImage-copy_main {
    font-size: 42px;
  }
}

/* =======================================

バスタイム

======================================= */
.bathtime-resort-wrap {
  padding: 40px 0;
  position: relative;
}

.bathtime-resort-wrap::before {
  content: '=';
  position: absolute;
  font-size: 56px;
  color: #9e7349;
  top: 0;
  left: 0;
  margin-top: 60px;
  z-index: 1;
}

.bathtime-resort-wrap .bathtime-resort-title {
  width: 200px;
}

.bathtime-resort-wrap .bathtime-resort-img {
  width: 90%;
  margin: 0 auto;
}

.bathtime-resort-wrap .bathtime-resort-sub {
  position: absolute;
  font-weight: bold;
  font-size: 16px;
  bottom: 0;
  right: 0;
  margin-right: -120px;
  margin-bottom: 130px;
  transform: rotate(-270deg);
  transform-origin: left top;
}

.bathtime-resort-wrap .bathtime-resort-sub::before {
  content: '|　';
}

.bathtime-item-wrap {
  text-align: center;
  position: relative;
  font-weight: normal;
}

.bathtime-item-wrap span.bathtime-item-box {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  display: inline-block;
  background: #e4e4e4;
  z-index: 3;
  padding-left: 20px;
  padding-right: 20px;
}

.bathtime-item-wrap span.bathtime-item {
  display: inline-block;
  background-color: #bc537d;
  font-size: 12px;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  color: #fff;
  padding-top: 14px;
  margin-right: 20px;
}

.bathtime-item-wrap span.bathtime-item-title {
  display: inline-block;
  letter-spacing: 3px;
  font-size: 24px;
}

.bathtime-item-wrap:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #666;
  z-index: 1;
}

@media screen and (min-width: 768px) {
  .bathtime-resort-wrap {
    padding: 40px 0;
    position: relative;
  }
  .bathtime-resort-wrap::before {
    font-size: 56px;
    margin-top: 60px;
    margin-left: 30px;
  }
  .bathtime-resort-wrap .bathtime-resort-title {
    width: 280px;
  }
  .bathtime-resort-wrap .bathtime-resort-img {
    width: 80%;
  }
  .bathtime-resort-wrap .bathtime-resort-sub {
    font-size: 20px;
    margin-right: -100px;
    margin-bottom: 150px;
  }
  .bathtime-pro-box {
    width: 600px;
    margin: 0 auto;
  }
}

/* =======================================

らんきんぐ

======================================= */
.sectionRanking {
  position: relative;
  padding: 40px 0 20px;
  background: url(../images/ranking/ranking_bg.jpg) no-repeat center bottom/auto 100% #0d4bb7;
}

.ranking_lg {
  display: none;
  width: 100%;
  max-width: 890px;
  height: auto;
  margin: 0 auto;
}

.ranking_sm {
  display: block;
  width: 100%;
  max-width: 750px;
  height: auto;
  margin: 0 auto;
}

.rankingNote {
  padding-left: 1em;
  margin-top: 30px;
  font-size: 10px;
  line-height: 1.3;
  color: #fff;
  text-indent: -1em;
}

@media screen and (min-width: 480px) {
  .rankingNote {
    text-align: center;
  }
}

@media screen and (min-width: 768px) {
  .ranking_lg {
    display: block;
  }
  .ranking_sm {
    display: none;
  }
}

/* =======================================

このような悩み

======================================= */
/* セクション */
.sectionTrouble {
  padding-top: 0;
  background: #fff;
}

@media screen and (min-width: 768px) {
  .sectionTrouble {
    padding-top: 80px;
    background: url(../images/trouble/trouble_bg.jpg) no-repeat center top/1200px auto #fff;
  }
}

@media screen and (min-width: 1024px) {
  .sectionTrouble {
    background-size: 100% auto;
  }
}

/* 見出し */
.trouble_haed {
  margin: 0 0 20px;
  font-weight: normal;
}

.trouble_haed img {
  display: block;
  width: 100%;
  height: auto;
}

@media screen and (min-width: 768px) {
  .trouble_haed {
    width: 50%;
    margin: 0 0 40px;
  }
}

/* チェックリスト */
.checkList-trouble {
  margin-bottom: 50px;
}

@media screen and (min-width: 768px) {
  .checkList-trouble {
    width: 50%;
    margin-bottom: 80px;
  }
}

/* 悩みのリスト */
.trouble_examples {
  padding: 20px 0;
}

.troubleItem {
  padding: 0 20px;
}

.troubleItem:first-child {
  margin-bottom: 30px;
}

.troubleItem_img {
  margin-bottom: 20px;
}

.troubleItem_heading {
  font-size: 1.125rem;
  margin-bottom: 20px;
  font-weight: 500;
  color: #46464f;
}

.troubleItem_heading span {
  display: block;
  font-size: 0.66em;
}

@media screen and (min-width: 480px) {
  .trouble_examples {
    padding: 40px 0;
  }
  .troubleItem {
    padding: 0 40px;
  }
  .troubleItem:first-child {
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 768px) {
  .trouble_examples {
    padding: 40px 20px;
  }
  .troubleItem {
    float: left;
    width: 50%;
    padding: 0 20px;
  }
}

@media screen and (min-width: 1024px) {
  .trouble_examples {
    padding: 40px 0;
    margin: 0 -40px;
  }
  .troubleItem {
    float: left;
    width: 50%;
    padding: 0 40px;
  }
  .troubleItem:first-child {
    margin: 0;
  }
}

/* =======================================

知ってますか?

======================================= */
#know {
  padding: 100px 0;
  background: url(../images/know/know_bg-sm.jpg) no-repeat top center/100%;
}

@media screen and (min-width: 768px) {
  #know {
    padding: 100px 0;
    background: url(../images/know/know_bg.jpg) no-repeat top center/cover;
  }
}

/* 見出し */
.section-titlesub-know {
  text-align: center;
  color: #9e7349;
  font-size: 14px;
  letter-spacing: 3px;
}

.section-titlesub-know span {
  position: relative;
}

.section-titlesub-know span:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  margin-left: -40px;
  width: 30px;
  height: 1px;
  background-color: #9e7349;
}

.section-titlesub-know span:after {
  content: '';
  position: absolute;
  top: 50%;
  right: 0;
  margin-right: -40px;
  width: 30px;
  height: 1px;
  background-color: #9e7349;
}

.heading_lv2.heading_know {
  margin-bottom: 100px;
}

.heading_lv3.heading_know {
  max-width: 350px;
  padding-bottom: 10px;
  margin: 0 auto 30px;
  text-align: center;
  border-bottom: solid 1px #d5d5d5;
}

/* 各説明 */
.know_group {
  margin-bottom: 70px;
}

/* 効能 */
.kounou_list {
  justify-content: center;
  align-items: stretch;
}

.kounou_panel {
  background-color: #e8dbcf;
  padding: 0 20px 40px 20px;
  position: relative;
}

.kounou_panel02 {
  background-color: #e8dbcf;
  padding: 0 20px 40px 20px;
  position: relative;
}

.kounou_name {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin-top: -10px;
  font-size: 1.125rem;
  margin-left: 10px;
  margin-right: 10px;
  padding: 4px 20px;
  text-align: center;
  color: #fff;
  background-color: #9e7349;
  border-radius: 10px;
  font-weight: 400;
}

@media screen and (min-width: 768px) {
  .kounou_panel {
    min-height: 477px;
  }
  .kounou_panel02 {
    min-height: 511px;
  }
}

.kounou_ill {
  display: block;
  width: 100%;
  max-width: 400px;
  padding-top: 60px;
  margin: 0 auto;
}

.kounyuBtn .btn {
  width: 100%;
  max-width: 322px;
}

/* =======================================

メタケイ酸

======================================= */
#metakei {
  padding: 100px 0;
}

.sectionMetakei {
  background: url(../images/metakei/metakei_bg.jpg) no-repeat center/cover;
}

/* 含んでいます */
.including {
  font-size: 1.375rem;
  padding: 5px 10px;
  margin-bottom: 30px;
  font-weight: 500;
  color: #9e7349;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.4);
  border: solid 1px #9e7349;
}

.including span {
  display: inline-block;
  text-align: left;
}

@media screen and (min-width: 480px) {
  .including {
    font-size: 1.5rem;
  }
}

/* 働き */
.metakeiWork_head {
  font-size: 1.25rem;
  margin-bottom: 20px;
  font-weight: 300;
  text-align: center;
}

.metakeiWork_list {
  justify-content: center;
}

.metakeiWork_item {
  margin-bottom: 20px;
}

.metakeiWork_item:last-child {
  margin-bottom: 0;
}

@media screen and (min-width: 1024px) {
  .metakeiWork_item {
    margin-bottom: 0;
  }
}

.metakeiWork_box {
  height: 100%;
  padding: 20px;
  background-color: #fff;
}

.metakeiWork_title {
  font-size: 1.375rem;
  padding: 5px;
  margin-bottom: 5px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  background-color: #6696ca;
  border-radius: 5px;
}

.metakeiWork_ill {
  display: block;
  width: 100%;
  max-width: 400px;
  margin: 0 auto 10px;
}

/* =======================================

メタケイ酸の美肌の秘密……

======================================= */
.silica-big-title {
  text-align: center;
  color: #fff;
  font-size: 14vw;
  letter-spacing: 5px;
}

.silica_txt {
  margin-bottom: 200px;
}

.silica_txt p {
  max-width: 760px;
  margin: 0 auto;
}

/* 効果4つ */
.kouka_item {
  margin-bottom: 40px;
}

.kouka_item:last-child {
  margin-bottom: 0;
}

.kouka_heading {
  font-size: 1.375rem;
  padding: 10px;
  margin-bottom: 20px;
  color: #fff;
  text-align: center;
  background-color: #6696ca;
  border-radius: 50px;
  font-weight: 400;
}

.kouka_body {
  padding: 0 10px;
}

.kouka_chart {
  max-width: 450px;
  margin: 0 auto;
}

@media screen and (min-width: 768px) {
  .silica-img {
    width: 310px;
    margin: 0 auto;
  }
  .kouka_item:nth-last-child(2) {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 1024px) {
  .silica-big-title {
    text-align: center;
    color: #fff;
    font-size: 130px;
    letter-spacing: 5px;
  }
}

/* =======================================

美肌美人になるための“美肌の湯”

======================================= */
.section_but {
  padding-bottom: 40px;
}

.heading_bihadabijin {
  color: #9e7349;
  text-align: center !important;
}

.heading_bihadabijin span {
  display: inline-block;
}

.but {
  max-width: 680px;
  margin: 0 auto;
}

.but_but {
  font-size: 1.25rem;
  margin-bottom: 20px;
  color: #bc537d;
}

.checkList.checkList-but {
  margin-bottom: 0;
}

.checkList.checkList-but li {
  background-image: url(../images/icon_check_bk.svg);
}

@media screen and (min-width: 768px) {
  .but_but {
    float: left;
    width: 7em;
  }
  .but_list {
    padding-left: 7em;
  }
}

/* =======================================

製品紹介

======================================= */
.section_product {
  position: relative;
  padding: 100px 0;
}

/* タイトル */
.heading_product {
  margin: 20px 0;
  line-height: 1.5;
  text-align: center;
}

.heading_product_sub {
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
}

.heading_product_logo {
  display: block;
  max-width: 640px;
  margin: 20px auto 40px;
}

/* 写真と横並び部分 - 写真 */
.product_wrapper {
  position: relative;
  background-image: url(../images/product/img_product01_sp.png);
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: contain;
}

.product_wrapper {
  padding-top: 60%;
}

@media screen and (min-width: 1024px) {
  .product_wrapper {
    padding-top: 0;
    background-image: url(../images/product/img_product01_pc.png);
    background-position: center center;
  }
  .product_disc {
    padding-right: 50%;
  }
}

@media screen and (-ms-high-contrast: none) {
  /* ここに書く */
  .product_wrapper {
    background-image: url(../images/product/img_product01_pc.png), linear-gradient(0, #61c9f7 0%, #20b4f5 35%, #0085e3 100%);
  }
}

/* 写真と横並び部分 - テキスト */
.product_disc {
  margin-top: 20px;
}

.product_copy {
  font-size: 1.6875rem;
  margin-bottom: 20px;
  color: #46464f;
}

.product_copy span {
  display: inline-block;
}

.product_copy_sub {
  font-size: 1.125rem;
}

.product_info {
  padding-top: 30px;
  margin: 30px 0;
  border-top: solid 1px rgba(255, 255, 255, 0.35);
}

.product_info_seibun {
  margin-bottom: 20px;
}

.product_info_name {
  font-size: 1.6875rem;
  line-height: 1.5;
  color: #fff;
}

.product_info_name span {
  display: inline-block;
}

.product_info_sub {
  color: #fff;
}

.product_link .btn {
  font-size: 1.5rem;
  width: 100%;
  max-width: 320px;
  padding: 20px 0;
}

@media (min-width: 480px) and (max-width: 1023px) {
  .product_disc {
    text-align: center;
  }
}

@media screen and (min-width: 480px) {
  .product_copy_sub span {
    display: block;
  }
}

/* =======================================

リゾート

======================================= */
.section_resort {
  padding: 160px 0;
  background: url(../images/resort/resort_bg.jpg) no-repeat center top/cover;
}

@media screen and (min-width: 768px) {
  .section_resort {
    background: url(../images/resort/resort_bg.jpg) no-repeat center top/100% auto;
  }
}

/* 見出し */
.heading_resort {
  font-size: 1.6875rem;
  padding-top: 32%;
  margin-bottom: 30px;
  font-weight: 300;
  line-height: 1.5;
  color: #46464f;
  background: url(../images/resort/heading_resort_bg_sm.png) no-repeat 0 0/100% auto;
}

.heading_resort span {
  display: inline-block;
}

@media screen and (min-width: 480px) {
  .heading_resort {
    padding-top: 8%;
    background-image: url(../images/resort/heading_resort_bg_lg.png);
  }
}

/* 画像と横並び部分 - 画像 */
.resort_img {
  padding-top: 50%;
  margin-bottom: 20px;
  background: url(../images//resort/blue-lagoon-iceland.jpg) no-repeat center/cover;
  box-shadow: 7px 7px 20px -10px rgba(0, 0, 0, 0.2);
}

.resort_txt_bg img {
  display: block;
  width: 100%;
  max-width: 480px;
  margin: 0 auto;
}

@media screen and (min-width: 1024px) {
  .resort_img {
    margin-bottom: 0;
  }
  .resort_inner {
    display: flex;
    flex-direction: row-reverse;
  }
  .resort_img,
  .resort_txt {
    width: 50%;
  }
  .resort_txt {
    padding-right: 40px;
  }
}

/* =======================================

特徴

======================================= */
.feature_container {
  padding-top: 100px;
}

.heading_feature {
  font-size: 2.5rem;
  margin-bottom: 30px;
  font-weight: 500;
  line-height: 1.5;
  color: #fff;
  text-align: center;
}

.heading_feature span {
  display: block;
}

.heading_feature_logo {
  max-width: 160px;
  margin: 0 auto;
}

/* 特徴 */
.feature_item {
  position: relative;
  padding: 3%;
  margin-bottom: 40px;
  background-color: #fff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}

.feature_item:last-child {
  margin-bottom: 0;
}

.feature_head {
  font-size: 1.375rem;
  position: relative;
  padding-left: 50px;
  margin-bottom: 20px;
  font-weight: 500;
  line-height: 1.5;
  color: #9e7349;
}

.feature_flex {
  display: flex;
  align-items: center;
}

.feature_num {
  display: block;
  width: 80px;
  margin-right: 10px;
}

.feature_num img {
  display: block;
  width: 100%;
  height: auto;
}

.feature_img {
  padding-top: 50%;
  margin-bottom: 20px;
  background-position: center;
  background-size: cover;
}

@media screen and (min-width: 480px) {
  .feature_head {
    padding-left: 80px;
  }
  .feature_head span {
    display: inline-block;
  }
}

@media screen and (min-width: 768px) {
  .feature_num {
    width: 100px;
  }
  .feature_head {
    padding-left: 100px;
  }
  .feature_body {
    display: flex;
  }
  .feature_img {
    width: 41%;
    min-height: 205px;
    padding-top: 0;
    margin-bottom: 0;
  }
  .feature_txt {
    width: 59%;
    padding-left: 30px;
  }
}

/* =======================================

フッター

======================================= */
.footer_grid {
  align-items: center;
}

.footer_photo {
  margin-bottom: 30px;
}

.footer_photo img {
  display: block;
  width: 100%;
  height: auto;
  margin: 0 auto;
}

.footer_txt {
  line-height: 1.3;
}

.footer_name {
  font-size: 1.5rem;
  font-weight: bold;
  letter-spacing: 3px;
  margin-bottom: 10px;
}

.footer_name span {
  display: inline-block;
  font-size: 1rem;
}

.footer_price {
  margin-bottom: 20px;
}

.footer_price span {
  display: inline-block;
}

.footer_component {
  font-size: 0.8125rem;
}

.footer_link {
  margin-top: 30px;
}

.footer_link .btn {
  width: 100%;
}

.footer_txt .footer_link {
  display: none;
}

.footer_info {
  font-size: 0.8125rem;
  padding-top: 20px;
  margin-top: 30px;
  line-height: 1.3;
  border-top: solid 1px #ddd;
}

@media screen and (min-width: 480px) {
  .footer_photo {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 768px) {
  .footer_txt .footer_link {
    display: block;
  }
  .footer_link .btn {
    width: auto;
  }
}

/* フッター */
.footer {
  padding: 20px;
  border-top: solid 1px #ddd;
}

.footer_logo {
  text-align: center;
}

.footer_logo img {
  width: 100%;
  max-width: 300px;
}

.copyright {
  padding-top: 5px;
  font-size: 12px;
  text-align: center;
}

.copyright a {
  color: #000;
  text-decoration: underline;
}

.copyright a:visited {
  color: #000;
}

.copyright a:hover {
  text-decoration: none;
}

@media screen and (min-width: 768px) {
  .footer_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
