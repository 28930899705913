/* =======================================

特徴

======================================= */
.feature_container {
	padding-top: 100px;
}

.heading_feature {
	@include font-size(40);

	margin-bottom: $spaceMedium;
	font-weight: 500;
	line-height: 1.5;
	color: #fff;
	text-align: center;

	span {
		display: block;
	}
}

.heading_feature_logo {
	max-width: 160px;
	margin: 0 auto;
}

/* 特徴 */
.feature_item {
	position: relative;
	padding: 3%;
	margin-bottom: $spaceLarge;
	background-color: #fff;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);

	&:last-child {
		margin-bottom: 0;
	}
}

.feature_head {
	@include font-size(22);

	position: relative;
	padding-left: 50px;
	margin-bottom: $spaceSmall;
	font-weight: 500;
	line-height: 1.5;
	color: $mainBrown;
}

.feature_flex{
	display: flex;
	align-items: center;
}

.feature_num {

	display: block;
	width: 80px;
	margin-right: 10px;

	img {
		display: block;
		width: 100%;
		height: auto;
	}
}

.feature_img {
	padding-top: 50%;
	margin-bottom: $spaceSmall;
	background-position: center;
	background-size: cover;
}

@include sm-min {
	.feature_num {

	}

	.feature_head {
		padding-left: 80px;

		span {
			display: inline-block;
		}
	}
}

@include md-min {
	.feature_num {
		width: 100px;
	}

	.feature_head {
		padding-left: 100px;
	}

	.feature_body {
		display: flex;
	}

	.feature_img {
		width: 41%;
		min-height: 205px;
		padding-top: 0;
		margin-bottom: 0;
	}

	.feature_txt {
		width: 59%;
		padding-left: $spaceMedium;
	}
}
