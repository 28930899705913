// ======================================
// クリア
// ======================================
@mixin clearfix {
	&::after {
		display: block;
		clear: both;
		content: "";
	}
}

// ======================================
// コンテナー
// ======================================
@mixin container {
	max-width: $containerSmall + $gridSpace;
	padding: 0 ($gridSpace/2);
	margin: 0 auto;

	@include lg-min {
		max-width: $container + $gridSpace;
	}
}

@mixin containerSmall {
	max-width: $containerSmall + $gridSpace;
	padding: 0 ($gridSpace/2);
	margin: 0 auto;
}

// ======================================
// メディアクエリ
// ======================================
// モバイルファースト
@mixin sm-min {
	@media screen and (min-width: $breakpoint-sm) {
		@content;
	}
}

@mixin md-min {
	@media screen and (min-width: $breakpoint-md) {
		@content;
	}
}

@mixin lg-min {
	@media screen and (min-width: $breakpoint-lg) {
		@content;
	}
}

@mixin xl-min {
	@media screen and (min-width: $breakpoint-xl) {
		@content;
	}
}
// PCファースト
@mixin xl-max {
	@media screen and (max-width: #{$breakpoint-xl - 1}) {
		@content;
	}
}

@mixin lg-max {
	@media screen and (max-width: #{$breakpoint-lg - 1}) {
		@content;
	}
}

@mixin md-max {
	@media screen and (max-width: #{$breakpoint-md - 1}) {
		@content;
	}
}

@mixin sm-max {
	@media screen and (max-width: #{$breakpoint-sm - 1}) {
		@content;
	}
}

// ======================================
// グラデーション
// ======================================
@mixin gradientLin {
	background: rgb(0, 133, 227);
	background: linear-gradient(0, rgba(97, 201, 247, 1) 0%, rgba(32, 180, 245, 1) 35%, rgba(0, 133, 227, 1) 100%);
}

@mixin gradientLinBack {
	background: rgb(0, 133, 227);
	background: linear-gradient(0, rgba(0, 133, 227, 1) 0%, rgba(32, 180, 245, 1) 65%, rgba(97, 201, 247, 1) 100%);
}

@mixin gradientRad {
	background: rgb(234, 248, 255);
	background: radial-gradient(circle, rgba(234, 248, 255, 1) 0%, rgba(210, 241, 255, 1) 100%);
}

// ======================================
// フォントサイズ
// ======================================
@mixin font-size($size) {
	font-size: (1rem * ($size/16));
}

@mixin font-size_smvw($size) {
	font-size: (1rem * ($size/16));

	@include sm-max {
		font-size: $size/480*100vw;
	}
}
