/* =======================================

要素いろいろ

======================================= */

/* ボタン */
.btn {
	display: inline-block;
	padding: 20px 3em;
	color: #fff;
	text-align: center;
	background-color: $mainRed;
	background-position: right 1em center;
	background-size: auto 1em;
	border-radius: 50px;
	transition: opacity 0.25s;

	&:hover {
		opacity: 0.7;
	}
}

// .btn_launch {
// 	background-image: url(../images/icon_launch.svg);
// }

/* 強調 */
em {
	font-style: normal;
	font-weight: bold;
}

strong {
	font-style: normal;
	font-weight: bold;
	color: $mainRed;
}

.marker {
	background: linear-gradient(transparent 70%, #ff0 70%);
}

