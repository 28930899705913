/* =======================================

美肌美人になるための“美肌の湯”

======================================= */
.section_but {
	padding-bottom: 40px;
	// border-top: solid 2px #d2d8e8;
}

.heading_bihadabijin {
	color: $mainBrown;
	text-align: center !important;

	span {
		display: inline-block;
	}
}

.but {
	max-width: 680px;
	margin: 0 auto;
}

.but_but {
	@include font-size(20);

	margin-bottom: $spaceSmall;
	color: $mainRed;
}

.checkList.checkList-but {
	margin-bottom: 0;

	li {
		background-image: url(../images/icon_check_bk.svg);
	}
}

@include md-min {
	.but_but {
		float: left;
		width: 7em;
	}

	.but_list {
		padding-left: 7em;
	}
}
