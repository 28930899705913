/* =======================================

リゾート

======================================= */
.section_resort {
	padding: 160px 0;
	// border-top: solid 2px #d2d8e8;
	background: url(../images/resort/resort_bg.jpg) no-repeat center top / cover;

	@include md-min {
		background: url(../images/resort/resort_bg.jpg) no-repeat center top / 100% auto;
	}
}

/* 見出し */
.heading_resort {
	@include font-size(27);

	padding-top: 32%;
	margin-bottom: $spaceMedium;
	font-weight: 300;
	line-height: 1.5;
	color: #46464f;
	background: url(../images/resort/heading_resort_bg_sm.png) no-repeat 0 0 / 100% auto;

	span {
		display: inline-block;
	}

	@include sm-min {
		padding-top: 8%;
		background-image: url(../images/resort/heading_resort_bg_lg.png);
	}
}

/* 画像と横並び部分 - 画像 */
.resort_img {
	padding-top: 50%;
	margin-bottom: $spaceSmall;
	background: url(../images//resort/blue-lagoon-iceland.jpg) no-repeat center / cover;
	box-shadow: 7px 7px 20px -10px rgba(#000, 0.2);
}

.resort_txt_bg {
	img {
		display: block;
		width: 100%;
		max-width: 480px;
		margin: 0 auto;
	}
}

@include lg-min {
	.resort_img {
		margin-bottom: 0;
	}

	.resort_inner {
		display: flex;
		flex-direction: row-reverse;
	}

	.resort_img,
	.resort_txt {
		width: 50%;
	}

	.resort_txt {
		padding-right: $gridSpace;
	}
}
