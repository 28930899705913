/* =======================================

らんきんぐ

======================================= */
.sectionRanking {
	position: relative;
	padding: 40px 0 20px;
	background: url(../images/ranking/ranking_bg.jpg) no-repeat center bottom / auto 100% #0d4bb7;
}

.ranking_lg {
	display: none;
	width: 100%;
	max-width: 890px;
	height: auto;
	margin: 0 auto;
}

.ranking_sm {
	display: block;
	width: 100%;
	max-width: 750px;
	height: auto;
	margin: 0 auto;
}

.rankingNote {
	padding-left: 1em;
	margin-top: 30px;
	font-size: 10px;
	line-height: 1.3;
	color: #fff;
	text-indent: -1em;
}

@include sm-min {
	.rankingNote {
		text-align: center;
	}
}

@include md-min {
	.ranking_lg {
		display: block;
	}

	.ranking_sm {
		display: none;
	}
}
