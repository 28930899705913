/* =======================================

ヘッダー

======================================= */

/* イメージ部分 */
.mainImage {
	display: flex;
	flex-direction: column;
	min-height: 300px;
	max-height: 400px;
}

.mainImage-img {
	flex-grow: 1;
	background: url(../images/header/mainimage.jpg) no-repeat top / cover;
}

.mainImage-img_title {
	padding: 10px 40px;
	color: $mainBrown;
	text-align: center;

}

.mainImage-img_logo {
	img {
		width: 100%;
		max-width: 410px;
		height: auto;
		vertical-align: bottom;
	}
}

.mainImage-img_name {
	@include font-size(20);
}


@include sm-min {
	.mainImage {
		display: flex;
		flex-direction: column;
		min-height: 500px;
		max-height: 612px;
	}
	.mainImage-img {
		background: url(../images/header/mainimage.jpg) no-repeat center / cover;
		height: 100%;
	}
}


@include md-min {
	.mainImage {
		display: flex;
		flex-direction: column;
		min-height: 600px;
		max-height: 812px;
	}

	.mainImage-img_title {
		padding: 100px 20px 30px 20px;
		color: $mainBrown;
	}

	.mainImage-img {
		//padding: 8vh 0 0;
	}
}

@include lg-min {
	.mainImage-img {
		padding: 70px 0 0;
	}
}

/* コピー部分 */
.mainImage-txt {
	padding: 20px 20px 40px;
	background-color: #eff7ff;
}

.mainImage-txt_inner {
	display: flex;
	width: 100%;
	max-width: 850px;
	margin: 0 auto;
}

.mainImage-product {
	position: relative;
	width: 30%;

	img {
		position: absolute;
		right: 0;
		bottom: 0;
		display: block;
		width: 100%;
	}
}

.mainImage-copy {
	padding-left: 5%;
}

.mainImage-copy_main {
	font-size: 6.2vw;
	font-weight: normal;
	// font-weight: 600;
	line-height: 1.5;
	color: $mainBrown;
}

.mainImage-copy_sub {
	margin: 20px 0 0;
}

@include md-min {
	.mainImage-copy_main {
		font-size: 5.9vw;
	}
}

@include lg-min {
	.mainImage-product {
		width: 20%;
	}

	.mainImage-copy_sub {
		margin: 10px 0 0;
	}

	.mainImage-copy_main {
		font-size: 42px;
	}
}
