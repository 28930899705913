/* =======================================

リスト

======================================= */
.list {
	padding: 0 0 0 1.5em;
	margin: 0 0 $spaceMedium;

	li {
		margin: 0 0 $spaceXSmall;

		&:last-child {
			margin: 0;
		}
	}
}

.checkList {
	padding: 0;
	margin: 0 0 $spaceMedium;
	list-style: none;

	li {
		@include font-size(20);

		padding-left: 1.5em;
		margin: 0 0 $spaceXSmall;
		background: url(../images/icon_check_2c.svg) no-repeat 0 0.4em / auto 1em;

		&:last-child {
			margin: 0;
		}

		span {
			display: inline-block;
		}
	}
}
