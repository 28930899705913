/* =======================================

知ってますか?

======================================= */
#know {
	padding: 100px 0;
	background: url(../images/know/know_bg-sm.jpg) no-repeat top center /100%;

}

@include md-min {
	#know {
		padding: 100px 0;
		background: url(../images/know/know_bg.jpg) no-repeat top center / cover;
	}
}


/* 見出し */
.section-titlesub-know{
   text-align: center;
	color: $mainBrown;
	font-size: 14px;
	letter-spacing: 3px;
  span{
		position: relative;
  	&:before{
			content: '';
			position: absolute;
			top:50%;
			left: 0;
			margin-left: -40px;
			width: 30px;
			height: 1px;
			background-color: $mainBrown;
  	}
		&:after{
			content: '';
			position: absolute;
			top:50%;
			right: 0;
			margin-right: -40px;
			width: 30px;
			height: 1px;
			background-color: $mainBrown;
  	}
	}

}



.heading_lv2.heading_know {
	margin-bottom: 100px;
}

.heading_lv3.heading_know {
	max-width: 350px;
	padding-bottom: 10px;
	margin: 0 auto $spaceMedium;
	text-align: center;
	border-bottom: solid 1px #d5d5d5;
}

/* 各説明 */
.know_group {
	margin-bottom: 70px;
}

/* 効能 */
.kounou_list {
	justify-content: center;
	align-items:stretch;

}


.kounou_panel{
	background-color: #e8dbcf;
	padding: 0 20px 40px 20px;
	position: relative;

}

.kounou_panel02{
	background-color: #e8dbcf;
	padding: 0 20px 40px 20px;
	position: relative;
}


.kounou_name {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;

	margin-top: -10px;
	@include font-size(18);
	margin-left: 10px;
	margin-right: 10px;

	padding: 4px 20px;
	text-align: center;
	color:#fff;
	background-color: $mainBrown;
	border-radius: 10px;
	font-weight: 400;

	@include md-min {

	}

	@include lg-min {

	}
}


@include md-min {

	.kounou_panel{
		min-height: 477px;
	}
	.kounou_panel02{
		min-height: 511px;
	}
}

.kounou_ill {
	display: block;
	width: 100%;
	max-width: 400px;
	padding-top: 60px;
	margin: 0 auto;
}

.kounyuBtn .btn {
	width: 100%;
	max-width: 322px;
}
